import { useCkeditorStore } from '@/components/Ckeditor/stores/ckeditor-store';
import { useDraft } from '@/composables/use-draft';
import draftsApiService from '@/services/drafts-api-service';
import { listDocuments, updateDocument } from '@/services/superdoc-services';
import templateApiService from '@/services/templates-api-service';
import { SuperdocService } from '@/services/superdoc-services';
import { useHarbourStore } from '@/stores/harbour-store';
import { useSuperdocStore } from '@/stores/superdoc-store';
import { publishEvent } from '@/utils/bus';
import { DOCX, PDF } from '@harbour-enterprises/superdoc';
import { defineStore } from 'pinia';
import Vue, { nextTick } from 'vue';
import HrbrDraftsTemplateModal from '../components/HrbrDraftsTemplateModal.vue';

import {
  createDraft,
  createSuperdocDraft,
  defineDraftRole,
  DraftMainGroups,
  DraftRoles,
  DraftStatuses,
  DraftTypes,
  DraftStates
} from '@/domain/drafts/draft';

import { CK_BUNDLE_VERSION } from '@components/Ckeditor/lib/bundle-version.js';
import {
  DialogProgrammatic as Dialog,
  ModalProgrammatic as Modal,
  ToastProgrammatic as Toast,
} from 'buefy';

export const useDraftsStore = defineStore('drafts', {
  state: () => ({
    harbourStore: useHarbourStore(),
    ckeditorStore: useCkeditorStore(),
    superdocStore: useSuperdocStore(),

    myDrafts: [],
    draftGroups: [DraftMainGroups.MyDrafts, DraftMainGroups.SharedDrafts],
    activeGroup: DraftMainGroups.MyDrafts,
    isDraftsInitialized: false,

    agGridApi: null,
    agGridColumnApi: null,
    agGridPaneApi: null,
    gridReference: null,

    textFilterValue: '',
    quickFilterTimeout: null,

    emailsToNames: {},

    isRenamingInSidebarPane: false,
    isLoadingView: false,

    draftTemplateRemovedInputs: [],
    draftTemplateChangedData: {},
  }),

  getters: {
    activeGroupTitle: (state) => {
      const titles = {
        [DraftMainGroups.MyDrafts]: 'My drafts',
        [DraftMainGroups.SharedDrafts]: 'Shared with me',
      };
      return titles[state.activeGroup] || '';
    },

    isUserOrgAdmin() {
      const roles = this.harbourStore.contextDict?.auth_roles ?? [];
      return roles.includes('orgAdmin');
    },

    superdocCollaboratorTypes() {
      return {
        viewer: 'reader',
        suggester: 'commentator',
        editor: 'writer',
      };
    },
  },

  actions: {
    setDrafts(reactiveDrafts) {
      this.myDrafts = reactiveDrafts;
    },

    setActiveGroup(group) {
      this.activeGroup = group;
    },

    findDraftById(id) {
      const draft = this.myDrafts.find((i) => i.id === id);
      return draft || null;
    },

    findDraftByFileId(ckFileId) {
      const draft = this.myDrafts.find((i) => i.ckFileId === ckFileId);
      return draft || null;
    },

    setDraftName(id, name) {
      const draft = this.findDraftById(id);
      if (draft) draft.name = name;
    },

    setDraftNameByFileId(ckFileId, name) {
      const draft = this.findDraftByFileId(ckFileId);
      if (draft) draft.name = name;
    },

    setDraftStatus(id, status) {
      const draft = this.findDraftById(id);
      if (draft) draft.status = status;
    },

    async storeDraft({
      name,
      agreementId,
      ckFileId = null,
      role = DraftRoles.Agreement,
      status = DraftStatuses.Started,
      type = DraftTypes.Docx,
      copiedFrom = null,
      isSuperdoc = false,
      superdocId = null,
    }) {
      try {
        const isSuperdocDraft = isSuperdoc || type === DraftTypes.SuperDoc;
        const createDraftPayload = {
          name,
          ckFileId,
          agreementId,
          role,
          status,
          type, 
          copiedFrom,
          isSuperdoc: isSuperdocDraft,
        }

        let draftData = null;
        let draft = null;

        if (isSuperdocDraft) {  
          if (!superdocId) throw new Error('Error - Superdoc ID is required for Superdoc drafts');
          draftData = await SuperdocService.getEditorInstanceById(superdocId);
          draft = createSuperdocDraft(draftData);
        } else {
          draftData = await draftsApiService.createDraft(createDraftPayload);
          draft = createDraft(draftData);
        }

        publishEvent('drafts:created', draft);
        return draft;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async initDrafts() {
      try {
        await this.loadDrafts();
      } finally {
        this.isDraftsInitialized = true;
      }
    },

    async loadSuperdocs() {
      if (!this.superdocStore?.hasSuperDocBeta) return [];
      const states = [
        DraftStates.Draft,
        DraftStates.Template,
        DraftStates.TemplateDraft,
      ];
      const filter = { state: states.join(',') };

      try {
        return await listDocuments(filter);
      } catch (err) {
        console.error(err);
        return [];
      }
    },

    async loadDrafts() {
      try {
        const allDocuments = [this.loadAllDrafts(), this.loadSuperdocs()];
        let [draftsList, superdocs] = await Promise.all(allDocuments);
        const superdocItems = superdocs?.items ?? [];
        const superdocDraftsList = superdocItems.map((superdoc) => {
          return createSuperdocDraft(superdoc, {
            systemEmail: this.harbourStore.contextDict?.systememail,
          });
        });

        draftsList = [...draftsList, ...superdocDraftsList];

        const reactiveDrafts = [];
        for (const draft of draftsList) {
          const newDraft = this.createReactiveDraft(draft);
          reactiveDrafts.push(newDraft);
        }

        this.setDrafts(reactiveDrafts);
        this.getDraftsProfilePictures();
        this.getProfileNames();

        publishEvent('drafts:loaded', draftsList);
      } catch (err) {
        this.myDrafts = [];
        console.error(err);
      }
    },

    createReactiveDraft(draft) {
      const newDraft = useDraft(draft);
      newDraft.setPath();
      return newDraft;
    },

    async loadAllDrafts() {
      try {
        const respData = await draftsApiService.getDrafts();
        const draftsList = respData
          .map((item) => createDraft(item))
          .sort((a, b) => b.createdAt - a.createdAt);
        return draftsList;
      } catch (err) {
        console.error(err);
        return [];
      }
    },

    async loadDraftById(id) {
      if (!id) return null;
      try {
        const draftData = await draftsApiService.getDrafts({ draftId: id });
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async loadDraftByFileId(ckFileId) {
      if (!ckFileId) return null;
      try {
        const draftData = await draftsApiService.getDrafts({ ckFileId });
        if (!draftData) return null;
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async loadDraftNameByFileId(ckFileId) {
      if (!ckFileId) return null;
      try {
        const draftName = await draftsApiService.getDraftNameByCkFileId(ckFileId);
        return draftName;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async updateDraftNameByFileId(ckFileId, name) {
      if (!ckFileId) return null;

      const newDraftName = name.trim();
      try {
        this.setDraftNameByFileId(ckFileId, newDraftName);
        const draftName = await draftsApiService.setDraftNameByCkFileId(ckFileId, newDraftName);
        return draftName;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async updateDraftUpdatedTimeByFileId(ckFileId) {
      if (!ckFileId) return null;
      try {
        const time = await draftsApiService.updateDraftUpdatedTimeByCkFileId(ckFileId);
        return time;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async getDraftsProfilePictures() {
      if (!this.myDrafts.length) return;
      const emails = [];
      this.myDrafts.forEach((draft) => {
        emails.push(...draft.owner, ...draft.writer, ...draft.reader, ...draft.commentator);
      });
      this.harbourStore.loadProfilePictures(emails);
    },

    getProfileNames() {
      const localDataString = localStorage.getItem('hrbr_emailInputData');
      if (!localDataString) return;
      try {
        const localData = JSON.parse(localDataString);
        const { emailsToNames } = localData;
        this.emailsToNames = emailsToNames;
      } catch (err) {
        console.error(err);
        this.emailsToNames = {};
      }
    },

    async deleteSuperdoc(id) {
      const result = await Vue.prototype.$harbourData.delete(`/api/documents/${id}`);
      return result.data;
    },

    async deleteDraft(id) {
      if (!id) return;

      const draft = this.findDraftById(id);
      if (!draft) return;

      const isSuperdoc = draft.type === DraftTypes.SuperDoc;

      try {
        let draftData;

        if (isSuperdoc) {
          draftData = await this.deleteSuperdoc(id);
        } else {
          draftData = await draftsApiService.deleteDraft(id);
        }

        if (draftData) {
          const draftIdx = this.myDrafts.findIndex((i) => i.id === id);
          if (draftIdx !== -1) this.myDrafts.splice(draftIdx, 1);
        }

        if (isSuperdoc) {
          return createSuperdocDraft(draftData, {
            systemEmail: this.harbourStore.contextDict?.systememail,
          });
        }
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    deleteDraftWithConfirm(id) {
      const draft = this.findDraftById(id);
      if (!draft) return;

      const onConfirm = async () => {
        const draftData = await this.deleteDraft(id);
        if (draftData) {
          Toast.open({
            duration: 2500,
            message: 'Success - draft removed',
            position: 'is-top',
            type: 'is-success',
          });
        }
      };

      Dialog.confirm({
        title: 'Delete draft',
        message: `Are you sure you want to fully delete the draft ("${draft.name}")?`,
        confirmText: 'Delete',
        type: 'is-danger',
        onConfirm,
      });
    },

    async renameDraft(id, newName) {
      if (!id) return;

      const draft = this.findDraftById(id);
      if (!draft) return;

      const isSuperdoc = draft.type === DraftTypes.SuperDoc;
      const newDraftName = newName.trim();

      try {
        // Set optimistic update
        this.setDraftName(id, newDraftName);

        let draftData;
        if (isSuperdoc) {
          await this.updateDocumentTitle({ id, name: newDraftName });
          // The updateDocumentTitle method already updates the draft object
          // and refreshes the grid, so we can return the current draft
          return draft;
        } else {
          draftData = await draftsApiService.renameDraft(id, newDraftName);
          return createDraft(draftData);
        }
      } catch (err) {
        console.error('Error renaming draft:', err);
        // Revert optimistic update on error
        this.setDraftName(id, draft.name);
        return null;
      }
    },

    async renameDraftWithNotify(id, newName) {
      const nameLen = newName.trim().length;
      if (!nameLen) {
        Toast.open({
          duration: 2500,
          message: 'The name must have at least one character. Please try again.',
          position: 'is-top',
          type: 'is-warning',
        });
        return null;
      }

      try {
        const draftData = await this.renameDraft(id, newName);
        if (draftData) {
          Toast.open({
            duration: 2000,
            message: 'Successfully renamed draft',
            position: 'is-top',
            type: 'is-success',
          });
        }
        return draftData;
      } catch (err) {
        Toast.open({
          duration: 2500,
          message: 'Failed to rename draft. Please try again.',
          position: 'is-top',
          type: 'is-danger',
        });
        return null;
      }
    },

    async changeDraftStatus(id, status) {
      if (!id) return;

      try {
        this.setDraftStatus(id, status);
        const draftData = await draftsApiService.updateStatus(id, status);
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async copyDraft({ draft, name, addCopyToTitle = false }) {
      const strategy = {
        [DraftTypes.Docx]: async () => {
          return await this.copyCkDraft({
            draft,
            name,
            addCopyToTitle,
          });
        },
        [DraftTypes.Pdf]: async () => {
          return await this.copyPdfDraft({
            draft,
            name,
            addCopyToTitle,
          });
        },
        default: async () => {
          console.log('not implemented');
          return null;
        },
      };

      const handler = strategy[draft.type] || strategy.default;
      const result = await handler();
      return result;
    },

    async copyCkDraft({ draft, name, addCopyToTitle = false }) {
      const isTemplate = draft.role === DraftRoles.Template;

      try {
        const htmlDataPromise = this.ckeditorStore.getCkeditorTemplateHtml(draft.agreementId);
        const copyAgreementRespPromise = templateApiService.copyAgreementTemplateRequest({
          templateId: draft.agreementId,
          fileDisplayId: draft.ckFileId,
          isTemplate,
          addCopyToTitle,
        });
        const [htmlData, copyAgreementRespData] = await Promise.all([
          htmlDataPromise,
          copyAgreementRespPromise,
        ]);

        const newAgreementId = copyAgreementRespData.copiedagreementid;
        const newCkFileId = copyAgreementRespData.newfileid;
        const newName = name || `Copy of ${draft.name}`;

        const sameIds = draft.ckFileId === newCkFileId || draft.agreementId === newAgreementId;
        if (sameIds) throw new Error('Error - new Id is the same as old Id');

        const documentSharingPromise = this.ckeditorStore.saveCkeditorDocumentSharing({
          fileDisplayId: newCkFileId,
        });
        const ckeditorMetadataPromise = this.ckeditorStore.saveCkeditorMetadata({
          fileDisplayId: newCkFileId,
          refids: { activeagreementid: newAgreementId },
        });
        await Promise.all([documentSharingPromise, ckeditorMetadataPromise]);

        const newDraft = await this.storeDraft({
          name: newName,
          ckFileId: newCkFileId,
          agreementId: newAgreementId,
          role: defineDraftRole(isTemplate),
          copiedFrom: draft.id,
        });
        return {
          draft: newDraft,
          htmlData,
          agreementId: newAgreementId,
          ckFileId: newCkFileId,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async copyPdfDraft({ draft, name, addCopyToTitle = false }) {
      const isTemplate = draft.role === DraftRoles.Template;

      try {
        const copyAgreementRespData = await templateApiService.copyAgreementTemplateRequest({
          templateId: draft.agreementId,
          fileDisplayId: draft.ckFileId,
          isTemplate,
          addCopyToTitle,
        });

        const newAgreementId = copyAgreementRespData.copiedagreementid;
        const newName = name || `Copy of ${draft.name}`;

        const sameIds = draft.agreementId === newAgreementId;
        if (sameIds) throw new Error('Error - new Id is the same as old Id');

        const newDraft = await this.storeDraft({
          name: newName,
          agreementId: newAgreementId,
          role: defineDraftRole(isTemplate),
          copiedFrom: draft.id,
          type: DraftTypes.Pdf,
        });
        return {
          draft: newDraft,
          htmlData: null,
          agreementId: newAgreementId,
          ckFileId: null,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async createDraftFromTemplate({ template, name, isTemplate = false, addCopyToTitle = false }) {
      const strategy = {
        [DraftTypes.Docx]: async () => {
          return await this.createCkDraftFromTemplate({
            template,
            name,
            isTemplate,
            addCopyToTitle,
          });
        },
        [DraftTypes.SuperDoc]: async () => {
          return await this.createSuperdocDraftFromTemplate({template, name});
        },
        [DraftTypes.pdf]: async () => {
          return await this.createPdfDraftFromTemplate({
            template,
            name,
            isTemplate,
            addCopyToTitle,
          });
        },
        default: async () => {
          console.log('not implemented');
          return null;
        },
      };

      const templateType = this.getTemplateType(template);
      const handler = strategy[templateType] || strategy.default;
      const result = await handler();
      return result;
    },

    getTemplateType(template) {
      if (!template) throw new Error('Error - template is null');

      // check if superdoc
      if (template.superdocId) return DraftTypes.SuperDoc;

      // check if ckeditor
      const customInputs = template.custom_input_fields_json;
      let ckFileId = customInputs.agreementcustominputfieldsjson?.ckeditoragreementid;
      if (!ckFileId) ckFileId = customInputs.ckeditoragreementid;
      const isCkTemplate = !!ckFileId;
      if (isCkTemplate) return DraftTypes.Docx;

      // assume pdf
      return DraftTypes.Pdf;
    },

    async createCkDraftFromTemplate({
      template,
      name,
      isTemplate = false,
      addCopyToTitle = false,
    }) {
      if (!template) return;

      try {
        const agreementId = template.agreement_id;
        const customInputs = template.custom_input_fields_json;
        let ckFileId = customInputs.agreementcustominputfieldsjson?.ckeditoragreementid;
        if (!ckFileId) ckFileId = customInputs.ckeditoragreementid;

        const htmlDataPromise = this.ckeditorStore.getCkeditorTemplateHtml(agreementId);
        const copyAgreementRespPromise = templateApiService.copyAgreementTemplateRequest({
          templateId: agreementId,
          fileDisplayId: ckFileId,
          isTemplate,
          addCopyToTitle,
        });

        const [htmlData, copyAgreementRespData] = await Promise.all([
          htmlDataPromise,
          copyAgreementRespPromise,
        ]);

        const newAgreementId = copyAgreementRespData.copiedagreementid;
        const newCkFileId = copyAgreementRespData.newfileid;
        const newName = name || `Copy of ${template.title}`;

        const sameIds = ckFileId === newCkFileId || agreementId === newAgreementId;
        if (sameIds) throw new Error('Error - new Id is the same as old Id');

        const documentSharingPromise = this.ckeditorStore.saveCkeditorDocumentSharing({
          fileDisplayId: newCkFileId,
        });
        const ckeditorMetadataPromise = this.ckeditorStore.saveCkeditorMetadata({
          fileDisplayId: newCkFileId,
          refids: { activeagreementid: newAgreementId },
        });
        await Promise.all([documentSharingPromise, ckeditorMetadataPromise]);

        const newDraft = await this.storeDraft({
          name: newName,
          ckFileId: newCkFileId,
          agreementId: newAgreementId,
          role: defineDraftRole(isTemplate),
        });
        return {
          draft: newDraft,
          htmlData,
          agreementId: newAgreementId,
          ckFileId: newCkFileId,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async createSuperdocDraftFromTemplate({template, name}) {
      if (!template) return;

      try {
        const agreementId = template.agreement_id;

        const copyAgreementPayload = {
          templateId: agreementId,
          fileDisplayId: null,
          isTemplate: false,
          addCopyToTitle: false,
        }
        if (name) copyAgreementPayload.newTitle = name;
        const copyAgreementResp = await templateApiService.copyAgreementTemplateRequest(copyAgreementPayload);

        const newAgreementId = copyAgreementResp.copiedagreementid;
        const newSuperdocId = copyAgreementResp.new_superdoc_id;
        const newAgreementTitle = copyAgreementResp.copiedagreementtitle || `Copy of ${template.title}`;

        const sameIds = agreementId === newAgreementId || template.superdocId === newSuperdocId;
        if (sameIds) throw new Error('Error - new Id is the same as old Id');

        const newDraft = await this.storeDraft({
          name: newAgreementTitle,
          agreementId: newAgreementId,
          superdocId: newSuperdocId,
          role: defineDraftRole(true),
          type: DraftTypes.SuperDoc,
        });
        return {
          draft: newDraft,
          agreementId: newAgreementId,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async createPdfDraftFromTemplate({
      template,
      name,
      isTemplate = false,
      addCopyToTitle = false,
    }) {
      if (!template) return;

      try {
        const agreementId = template.agreement_id;
        const copyAgreementRespData = await templateApiService.copyAgreementTemplateRequest({
          templateId: agreementId,
          isTemplate,
          addCopyToTitle,
        });

        const newAgreementId = copyAgreementRespData.copiedagreementid;
        const newName = name || `Copy of ${template.title}`;

        const sameIds = agreementId === newAgreementId;
        if (sameIds) throw new Error('Error - new Id is the same as old Id');

        const newDraft = await this.storeDraft({
          name: newName,
          agreementId: newAgreementId,
          role: defineDraftRole(isTemplate),
          type: DraftTypes.Pdf,
        });
        return {
          draft: newDraft,
          htmlData: null,
          agreementId: newAgreementId,
          ckFileId: null,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    getFileOpener() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      const acceptTypes = ['.docx', DOCX, PDF];
      fileInput.accept = acceptTypes.join(',');

      const openFile = () => {
        return new Promise((resolve, reject) => {
          fileInput.onchange = async () => {
            const files = fileInput.files;
            if (!files) return resolve(null);
            const file = files.item(0);
            if (!file) return resolve(null);
            return resolve({ file });
          };
          fileInput.oncancel = () => resolve(null);
          fileInput.onerror = reject;
          fileInput.click();
        });
      };
      return openFile;
    },

    async openFromUpload({ parentComponent, isBlankDocument = false }) {
      try {
        let file;
        if (!isBlankDocument) {
          const open = this.getFileOpener();
          const result = await open();
          if (!result) return;
          file = result.file;
        }

        // Check for SuperDoc Beta access and route to SuperDoc if its not a PDF
        // and we have superdoc beta
        const isPdfFile = file?.type === PDF;
        if (this.superdocStore.hasSuperDocBeta && !isPdfFile) {
          return this.superdocStore.openSuperdocFromUpload({
            file,
            isBlankDocument,
            parent: parentComponent,
            mode: 'agreement',
          });
        }

        const fileName = file.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        const strategy = {
          [DraftTypes.Docx]: () => {
            this.openCkDocumentFromFile({
              file,
              parentComponent,
            });
          },
          [DraftTypes.Pdf]: () => {
            this.openPdfDocumentFromFile({
              file,
              parentComponent,
            });
          },
          default: () => {
            console.log('not implemented');
          },
        };
        const handler = strategy[fileExtension] || strategy.default;
        handler();
      } catch (err) {
        console.error(err);
      }
    },

    async copySharedLink(draft) {
      const strategy = {
        [DraftTypes.Docx]: async () => {
          return await this.copySharedCkLink(draft.ckFileId);
        },
        [DraftTypes.SuperDoc]: async () => {
          return await this.copySharedSuperdocLink(draft.id);
        },
        default: async () => {
          console.log('Not implemented.');
          return false;
        },
      };

      const handler = strategy[draft.type] ?? strategy.default;
      return await handler();
    },

    async copySharedCkLink(ckFileId) {
      const path = '/shared-agreement';
      const url = new URL(path, window.location.origin);
      const urlParams = new URLSearchParams();
      urlParams.set('docId', ckFileId);
      url.search = urlParams.toString();
      try {
        await navigator.clipboard.writeText(url);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    async copySharedSuperdocLink(draftId) {
      const path = `/document/${draftId}`;
      const url = new URL(path, window.location.origin);
      try {
        await navigator.clipboard.writeText(url);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    async copySharedLinkWithNotify(draft) {
      const result = await this.copySharedLink(draft);
      if (result) {
        Toast.open({
          duration: 2000,
          message: 'Copied document link!',
          position: 'is-top',
          type: 'is-success',
        });
      }
    },

    async getDocxFile({ agreementId, name }) {
      try {
        const html = await this.ckeditorStore.getCkeditorTemplateHtml(agreementId);
        const blob = await this.ckeditorStore.exportToDocx(name, html);
        return blob;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async downloadDocxFileWithNotify({ agreementId, name }) {
      Toast.open({
        duration: 3000,
        message: `Downloading .docx file...`,
        position: 'is-bottom',
        type: 'is-black',
      });

      const blob = await this.getDocxFile({ agreementId, name });
      if (!blob) {
        Toast.open({
          duration: 3000,
          message: `Error: Could not download .docx file. Try again later or contact support.`,
          position: 'is-top',
          type: 'is-danger',
        });
      }

      const blobUrl = URL.createObjectURL(blob);
      const linkElem = document.createElement('a');
      linkElem.href = blobUrl;
      linkElem.download = name;
      linkElem.click();
      URL.revokeObjectURL(blobUrl);
    },

    openBlankDocument({ parentComponent }) {
      // If user has SuperDoc Beta access, route to SuperDoc
      if (this.superdocStore.hasSuperDocBeta) {
        return this.superdocStore.openSuperdocFromUpload({
          isBlankDocument: true,
          parent: parentComponent,
          mode: 'agreement',
        });
      }

      const props = {
        creationMode: 'agreement',
        isBlankCkDocument: true,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    openCkDocumentFromFile({ file, parentComponent }) {
      const props = {
        creationMode: 'agreement',
        isCkDocumentFromFile: true,
        fileToUpload: file,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    openPdfDocumentFromFile({ file, parentComponent }) {
      const props = {
        creationMode: 'agreement',
        isPdfDocumentFromFile: true,
        fileToUpload: file,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    openDraftDocument({ draft, htmlData = ' ', addUrlParams = false, parentComponent }) {
      const strategy = {
        [DraftTypes.Docx]: () => {
          this.openCkDraftDocument({
            draft,
            htmlData,
            addUrlParams,
            parentComponent,
          });
        },
        [DraftTypes.Pdf]: () => {
          this.openPdfDraftDocument({
            draft,
            parentComponent,
          });
        },
        [DraftTypes.SuperDoc]: () => {
          if (!this.superdocStore.hasSuperDocBeta) return;
          this.superdocStore.openSuperdocFromDrafts({
            superdocId: draft.id,
            name: draft.name,
            mode: 'agreement',
            parentComponent,
          });
        },
        default: () => {
          console.log('not implemented');
        },
      };

      const handler = strategy[draft.type] || strategy.default;
      handler();
    },

    async openCkDraftDocument({ draft, htmlData = ' ', addUrlParams = false, parentComponent }) {
      this.isLoadingView = true;
      let fileBundleVersion = await this.ckeditorStore.getCkeditorFileVersion(draft.ckFileId);
      if (fileBundleVersion == '42.0.1' && CK_BUNDLE_VERSION == '42.0.0') {
        this.isLoadingView = false;
        const msg =
          "You can't edit this draft at the moment. Try again in a bit or contact suppport@harbourshare.com";
        alert(msg);
        Sentry.captureException(
          new Error(`Older ck bundle version, unable to edit ${draft.ckFileId}`),
        );
        Sentry.captureMessage(`Older ck bundle version, unable to edit ${draft.ckFileId}`);
        console.error(`Older ck bundle version, unable to edit ${draft.ckFileId}`);
        return;
      }
      this.isLoadingView = false;
      Vue.prototype.$openCkeditorModal({
        props: {
          fileVersionDisplayId: draft.ckFileId,
          uploadedFileName: draft.name,
          sourceDraftId: draft.id,
          // data will come from ckeditor cloud,
          // but null htmlData prop not allowed in ckeditor v40
          htmlData: htmlData || ' ',
          hasPolling: true,
          syncComplete: true,
          isAgreementEditorWorkflow: false,
          parent: parentComponent,
        },
        events: {
          'prepare-for-signature': (ckeditorData) => {
            this.openDraftForSignature({
              draft,
              ckeditorData,
              parentComponent,
            });
          },
        },
      });

      if (addUrlParams) {
        this.addCkUrlParams({
          action: 'draftdoc',
          ckFileId: draft.ckFileId,
        });
      }
    },

    openPdfDraftDocument({ draft, parentComponent }) {
      const props = {
        agreementId: draft.agreementId,
        sourceDraftId: draft.id,
        creationMode: draft.role,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    addCkUrlParams({ action, ckFileId }) {
      const url = new URL(window.location);
      if (action) url.searchParams.set('ckeditoraction', action);
      if (ckFileId) url.searchParams.set('ckeditorfileid', ckFileId);
      window.history.replaceState({}, '', url);
    },

    clearCkUrlParams() {
      const url = new URL(window.location);
      const hasAction = url.searchParams.has('ckeditoraction');
      const hasCkFileId = url.searchParams.has('ckeditorfileid');
      if (hasAction || hasCkFileId) {
        url.searchParams.delete('ckeditoraction');
        url.searchParams.delete('ckeditorfileid');
        window.history.replaceState({}, '', url);
      }
    },

    openDraftForSignature({ draft, ckeditorData, parentComponent, source = null }) {
      const props = {
        agreementId: draft.agreementId,
        ckeditorFileId: draft.ckFileId,
        sourceDraftId: draft.id,
        creationMode: draft.role,
        isSkipToCk: false,
        source,
        documentTitle: ckeditorData?.documentTitle || null,
        ckHtmlAnchors: ckeditorData?.anchors,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    openFromTemplateModal() {
      Modal.open({
        component: HrbrDraftsTemplateModal,
        hasModalCard: true,
        width: 320,
        canCancel: false,
      });
    },

    checkDraftSameOrg(orgId) {
      const contextOrgId = this.harbourStore.contextDict?.organizationid;
      return !!orgId && orgId === contextOrgId;
    },

    checkUserOrgAdminPermission(orgId) {
      const isDraftSameOrg = this.checkDraftSameOrg(orgId);
      return this.isUserOrgAdmin && isDraftSameOrg;
    },

    onDraftCreatedEvent(draft) {
      if (!draft) return;
      const newDraft = this.createReactiveDraft(draft);
      const newMyDrafts = [newDraft, ...this.myDrafts];
      this.myDrafts = newMyDrafts;
    },

    // TODO - rename for general use?
    async onShareSettingsUpdatedEvent(draftId) {
      if (!draftId) return;
      const draft = await this.loadDraftById(draftId);
      if (!draft) return;
      const newDraft = this.createReactiveDraft(draft);
      const index = this.myDrafts.findIndex((i) => i.id === draftId);
      if (index !== -1) {
        const newMyDrafts = [...this.myDrafts];
        newMyDrafts.splice(index, 1, newDraft);
        this.myDrafts = newMyDrafts;
        await nextTick();
        const rowNode = this.agGridApi?.getRowNode(draftId);
        if (rowNode) this.agGridApi?.redrawRows({ rowNodes: [rowNode] });
      }
    },

    updateGridTextFilter(currentText) {
      if (!currentText) {
        this.textFilterValue = '';
        this.agGridApi?.setQuickFilter('');
        return;
      }
      this.textFilterValue = currentText;

      clearTimeout(this.quickFilterTimeout);
      this.quickFilterTimeout = setTimeout(() => {
        const filterText = this.textFilterValue;
        this.agGridApi?.setQuickFilter(filterText);
      }, 200);
    },

    draftsGridAutoSize() {
      const gridReference = this.gridReference;
      const gridApi = this.agGridApi;
      const gridColumnApi = this.agGridColumnApi;
      this.harbourStore.autosizeGridByReference(gridReference, gridApi, gridColumnApi);
    },

    /**
     * SuperDoc: Triggered when a superdoc is shared with a user
     * @param {String} id The superdoc id
     * @param {Array} collaborators The list of superdoc collaborators
     * @returns {void}
     */
    onDocumentShared({ id, collaborators = [] }) {
      const draft = this.findDraftById(id);
      if (!draft) return;

      collaborators.forEach((user) => {
        const { role, immutableRole, inheritedRole } = user;
        const collaboratorType = this.superdocCollaboratorTypes[role];

        // Remove user from all other roles
        Object.values(this.superdocCollaboratorTypes).forEach((type) => {
          if (type === collaboratorType) return;
          draft[type] = draft[type].filter((email) => {
            return email !== user.email;
          });
        });

        // Add user to the selected role
        if (!draft[collaboratorType].includes(user.email)) {
          draft[collaboratorType].push(user.email);
        }

        // Add user to collaboratorsWithImmutableRoles if immutableRole is true
        if (immutableRole && !draft.collaboratorsWithImmutableRoles.includes(user.email)) {
          draft.collaboratorsWithImmutableRoles.push(user.email);
        }

        // Add user to collaboratorsWithInheritedRoles if inheritedRole is true
        if (inheritedRole && !draft.collaboratorsWithInheritedRoles.includes(user.email)) {
          draft.collaboratorsWithInheritedRoles.push(user.email);
        }
      });
    },

    /**
     * SuperDoc: Triggered when a superdoc collaborator is removed
     * @param {String} id The superdoc id
     * @param {Object} user The user object
     * @returns {void}
     */
    onRemoveCollaborator({ id, user }) {
      const draft = this.findDraftById(id);
      if (!draft) return;
      const { role } = user;

      const collaboratorType = this.superdocCollaboratorTypes[role];
      draft[collaboratorType] = draft[collaboratorType].filter((email) => {
        return email !== user.email;
      });
    },

    convertDraftCollaboratorsToSuperdoc(draft) {
      const collaborators = [];
      const collaboratorsWithImmutableRoles = draft.collaboratorsWithImmutableRoles || [];
      const colaboratorsWithInheritedRoles = draft.collaboratorsWithInheritedRoles || [];
      Object.entries(this.superdocCollaboratorTypes).forEach(([role, type]) => {
        draft[type].forEach((email) =>
          collaborators.push(
            this.convertToSuperdocUser(email, role, collaboratorsWithImmutableRoles, colaboratorsWithInheritedRoles),
          ),
        );
      });
      return collaborators;
    },

    convertToSuperdocUser(email, role, collaboratorsWithImmutableRoles, colaboratorsWithInheritedRoles) {
      const picture = this.harbourStore.profilePictures.find(
        (i) => i.email === email,
      )?.profileImageUrl;
      const name = this.emailsToNames[email];
      const immutableRole = collaboratorsWithImmutableRoles.includes(email) ? true : false;
      const inheritedRole = colaboratorsWithInheritedRoles.includes(email) ? true : false;

      return {
        email,
        name,
        picture,
        role,
        immutableRole,
        inheritedRole
      };
    },

    async updateDocumentTitle({ id, name }) {
      try {
        const result = await updateDocument(id, { name: name });
        const draft = this.findDraftById(id);
        if (draft && this.agGridApi) {
          draft.name = result.name;
          draft.updatedAt = result.updated_at;
          this.agGridApi?.refreshCells();
        }
        return result;
      } catch (err) {
        console.error('Error updating document title:', err);
        throw err;
      }
    },
  },
});
