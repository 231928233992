<script setup>
import '@static/superdoc/dist/style.css';

import Vue, { ref, onMounted, computed, getCurrentInstance, onBeforeUnmount, isRef } from 'vue';
import { DialogProgrammatic as Dialog } from 'buefy';
import { ToastProgrammatic as Toast } from 'buefy';
import { HarbourSuperdoc } from '@static/superdoc/dist/harbour-superdoc.es.js';
import { SuperdocService } from '@/services/superdoc-services';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { BlankDOCX, getFileObject, DOCX } from '@harbour-enterprises/superdoc';
import { createSuperdocDraft } from '@/domain/drafts/draft';
import { useHarbourStore } from '@/stores/harbour-store';
import { useSuperdocStore } from '@/stores/superdoc-store';
import { useSuperdoc } from '@/composables/superdoc/use-superdoc.js';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { publishEvent } from '@/utils/bus';
import { DraftStates } from '@/domain/drafts/draft';
const { proxy } = getCurrentInstance();
const draftsStore = useDraftsStore();
const harbourStore = useHarbourStore();
const superdocStore = useSuperdocStore();
const templatesStore = useTemplatesStore();

const emit = defineEmits(['close']);
const props = defineProps({
  file: {
    type: File,
    required: false,
  },
  superdocId: {
    type: String,
    required: false,
  },
  isBlankDocument: {
    type: Boolean,
    required: false,
  },
  superdocName: {
    type: String,
    required: false,
  },
  superdocPromise: {
    type: Promise,
    required: false,
  },
  contextDict: {
    type: Object,
    required: true,
  },
  isStandalone: {
    type: Boolean,
    required: false,
    default: false,
  },
  parent: {
    type: Object,
    required: false,
  },
  mode: {
    type: String,
    required: false,
  },
  template: {
    type: Object,
    required: false,
  },
  agreementId: {
    type: String,
    required: false,
  },
  linkId: {
    type: String,
    required: false,
  },
  linkObject: {
    type: Object,
    required: false,
  },
  superdocProp: {
    type: Object,
    required: false,
  },
  templateGroupId: {
    type: String,
  },
  editorProps: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  agreementEditorPrevFlow: {
    type: Boolean,
    default: false,
  },
});

let superdocModalInstance;
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);

const currentFile = ref(props.file || null);
const onNewSuperdoc = (superdoc) => {
  const draft = createSuperdocDraft(superdoc, {
    systemEmail: harbourStore.contextDict?.systememail,
  });
  publishEvent('drafts:created', draft);
};

const updateWindowSize = () => {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
};

const currentScale = ref(1);
const editorElem = ref(null);

const lastPinchDistance = ref(0);
/**
 * 
 * @param event touch event
 * @description Pinch to zoom handler.
 * Receives a touch event and sets the scale proprty of the editor accordingly.
 * Expects the editor element to be initialized.
 * @returns {void}
 */
const pinchHandler = (event) => {
  if (!editorElem.value || event.touches.length !== 2) return;
  event.preventDefault();
  const { touches } = event;

  // get finger distance
  const currentPinchDistance = Math.hypot(
    touches[0].clientX - touches[1].clientX,
    touches[0].clientY - touches[1].clientY
  );

  if (currentPinchDistance > lastPinchDistance.value) {
    // zoom out
    if (currentScale.value > 0.25) currentScale.value -= 0.01;
  } else {
    // zoom in
    if (currentScale.value < 1.5) currentScale.value += 0.01;
  }

  // update
  lastPinchDistance.value = currentPinchDistance;
  editorElem.value.style.transform = `scale(${currentScale.value})`;
};

/**
 * @description Scale editor to fit the screen.
 * Editor element is expected to be initialized.
 * @returns {void}
 */
const scaleEditorToFit = () => {
  if (!editorElem.value) return;

  // get screen size
  const screeenWidth = document.documentElement.clientWidth;

  // get document size
  let docWidth = editorElem.value.style.width;
  docWidth = docWidth.split('in')[0];  
  docWidth *= 96; // convert to pixels at 96 PPI

  if (screeenWidth >= docWidth) return;

  const widthPercentage = screeenWidth / docWidth;
  currentScale.value = widthPercentage;

  // delay so user can observe resize
  setTimeout(() => {
    editorElem.value.parentElement.style.transform = `scale(${currentScale.value})`;
    editorElem.value.parentElement.style.transformOrigin = '0 0';
  }, 500)
}

const onEditorCreate = () => {
  editorElem.value = document.querySelector('.editor-element.super-editor__element');
  scaleEditorToFit();
};

const loadBlankFile = async () => {
  let currentDate = superdocStore.getFormattedDate(null, 'mm.dd.yyyy');
  let name = `Untitled - ${currentDate}`;

  return await getFileObject(
    BlankDOCX,
    name,
    DOCX,
  );
};

/**
 * Open the full screen superdoc experience (harbour-superdoc). This will render
 * the Vue3 superdoc modal using SuperdocViewer component.
 *
 * @param file Optional file to initialize with
 */
const init = async (file) => {
  if (props.isBlankDocument) file = await loadBlankFile();
  currentFile.value = file;

  superdocModalInstance = new HarbourSuperdoc({
    elementId: 'sd-editor-container-superdoc',
    file,
    environment: import.meta.env.VITE_ENV,
    superdocId: props.superdocId,
    agreementId: props.agreementId,
    linkId: props.linkId || props.editorProps.linkDisplayId,
    superdocName: props.superdocName,
    context: props.contextDict,
    isStandalone: props.isStandalone,
    mode: props.mode,
    linkObject: props.linkObject,
    superdocProp: props.superdocProp,
    agreementEditorPrevFlow: props.agreementEditorPrevFlow,
    templatesGroups: templatesStore.templatesGroups,
    onNewSuperdoc,
    onTitleChange,
    onDocumentShared,
    onUserRemoved,
    onContinueToAE,
    onPrepareBookmark,
    onCopyAndContinueToAE,
    onEditorCreate,
  });

  superdocModalInstance.on('close', (params) => handleModalClose(params));
};

const handleModalClose = (params = {}) => {
  harbourStore.resetPrepareForBookmark();
  if (params.isUpdateLink) {
    Toast.open({
      message: 'Link updated successfully',
      type: 'is-success',
      position: 'is-top',
      duration: 3500,
    });
  };
  emit('close');
};

const onDocumentShared = ({ id, collaborators }) => {
  draftsStore.onDocumentShared({ id, collaborators });
};

const onUserRemoved = ({ id, user }) => {
  draftsStore.onRemoveCollaborator({ id, user });
};

const onContinueToAE = async (superdoc) => {
  harbourStore.resetPrepareForBookmark();
  const templateModes = [DraftStates.TemplateDraft, DraftStates.Template];
  const creationMode = templateModes.includes(superdoc.state) ? DraftStates.Template : 'agreement';

  const agreementId = isRef(superdoc.agreement?.id) ? superdoc.agreement?.id?.value : superdoc.agreement?.id;
  const agreementEditorProps = {
    ...props.editorProps,
    parent: proxy,
    creationMode,
    superdoc,
    agreementId: props.agreementId || agreementId,
    linkDisplayId: props.linkId || superdoc.link?.id?.value || props.editorProps.linkDisplayId,
    fullScreen: true,
  };

  if (props.template) {
    agreementEditorProps.agreementId = props.template.agreement_id;
    agreementEditorProps.templateGroupId = props.template.template_group_id;

    if (props.templateGroupId && creationMode === 'template') {
      agreementEditorProps.templateGroupId = props.templateGroupId;
    }
  }

  const superdocTemplateGroupId = isRef(superdoc.agreement?.templateGroupId) ? superdoc.agreement?.templateGroupId.value : superdoc.agreement?.templateGroupId;
  if (superdocTemplateGroupId) {
    agreementEditorProps.templateGroupId = superdocTemplateGroupId;
  }


  Vue.prototype.$openAgreementEditorModal({ props: agreementEditorProps })
  emit('close');
};

const onPrepareBookmark = ({ id, title }) => {
  harbourStore.prepareForSuperdocBookmark({ title, id });
};

const onCopyAndContinueToAE = async ({
  superdocId,
  agreementId,
  templateGroupId,
  signers,
  shouldCopy
}) => {
  let superdocData = null;
  let superdoc = null;

  const creatingToast = Toast.open({
    message: 'Creating template...',
    type: 'is-success',
    position: 'is-top',
    indefinite: true,
  });

  if (shouldCopy) {
    const response = await templatesStore.copyDocument({
      templateId: agreementId,
      fileDisplayId: null,
      isTemplate: true,
      addCopyToTitle: true,
      templateGroupId
    })
    const { copiedagreementid, new_superdoc_id } = response;

    superdocData = await SuperdocService.getDocument(new_superdoc_id);
    superdoc = useSuperdoc(superdocData);
    superdoc.agreement.id = copiedagreementid;
  } else {
    superdocData = await SuperdocService.getDocument(superdocId);
    superdoc = useSuperdoc(superdocData);
  }

  await SuperdocService.updateDocument(
    superdoc.id,
    { state: 'template-draft' }
  );

  // state will be used to determine creation mode by AE
  superdoc.state = 'template-draft';
  superdoc.agreement.templateGroupId = templateGroupId;
  superdoc.agreement.signers = signers;

  superdoc.setInitialized();

  creatingToast.close();
  handleModalClose();
  onContinueToAE(superdoc);
}

const onTitleChange = ({ id, title }) => {
  onPrepareBookmark({ id, title });
  if (props.isStandalone) {
    draftsStore.updateDocumentTitle({ id, name: title });
  } else {
    draftsStore.renameDraft(id, title);
  }
};

onMounted(async () => {
  init(props.file);
  window.addEventListener('resize', updateWindowSize);
});

onBeforeUnmount(() => {
  harbourStore.resetPrepareForBookmark();
  window.removeEventListener('resize', updateWindowSize);
});
</script>

<template>
  <div class="sd-editor-container">
    <div class="sd-editor-container__main hrbr-superdoc">
      <div @touchstart="pinchHandler" @touchmove="pinchHandler" class="sd-editor-container__superdoc"
        id="sd-editor-container-superdoc">
      </div>
    </div>
  </div>
</template>

<style>
.sd-editor-container__main button.modal-close>* {
  color: black !important;
  display: none;
}

.sd-editor-container__main button.modal-close.is-large::before,
.sd-editor-container__main button.modal-close.is-large::after {
  color: black !important;
}
</style>

<style lang="postcss" scoped>
.sd-editor-container {
  display: grid;
  width: 100%;
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  &__main {
    display: grid;
    width: 100%;
    height: 100%;
  position: relative;
    background-color: #fff;
}

  &__superdoc {
    display: grid;
  width: 100%;
  height: 100%;
  }
}
</style>
